import { getToken } from '@iso/lib/helpers/utility';
import authAction from '../redux/auth/actions';
import axios from 'axios';
import { store } from '../redux/store';

const customAxios = axios.create({
  baseURL: 'https://api.ourguide.xyz',
});

customAxios.interceptors.request.use((config) => {
  return {
    ...config,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + getToken().get('idToken'),
      ...config.headers,
    },
  };
});

customAxios.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response.status === 401) {
      store.dispatch(authAction.logout());
    }
    return Promise.reject(err);
  },
);

export default customAxios;
