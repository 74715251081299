import actions from './actions';

const initState = { user: null, user2: null };

export default function userReducer(state = initState, action) {
  switch (action.type) {
    case actions.UPDATE_USER_INFO:
      return {
        ...state,
        user: action.user,
      };
    case actions.UPDATE_USER2_INFO:
      return {
        ...state,
        user2: action.user2,
      };
    case actions.RESET_USER_INFO:
      return {
        ...state,
        user: null,
      };
    case actions.RESET_USER2_INFO:
      return {
        ...state,
        user2: null,
      };
    case actions.RESET_All_USERS_INFO:
      return initState;
    default:
      return state;
  }
}
