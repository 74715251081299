import antdKo from 'antd/lib/locale-provider/ko_KR';
import koMessages from '../locales/ko_KR.json';

const KoLang = {
  messages: {
    ...koMessages,
  },
  antd: antdKo,
  locale: 'ko-KR',
};
export default KoLang;
