import jwtDecode from 'jwt-decode';
import { all, takeEvery, put, fork } from 'redux-saga/effects';

import authActions from '../auth/actions';
import actions from './actions';

export function* updateUserInfo() {
  yield takeEvery(authActions.LOGIN_SUCCESS, function* (payload) {
    const { token } = payload;
    if (token) {
      const user = jwtDecode(token); // TODO: API를 통해서 사용자 정보를 가져오게 수정 필요
      yield put({
        type: actions.UPDATE_USER_INFO,
        user,
      });
    }
  });
}

export default function* rootSaga() {
  yield all([fork(updateUserInfo)]);
}
