import GlobalStyles from '@iso/assets/styles/globalStyle';
import React from 'react';
import { Provider } from 'react-redux';
import { SWRConfig } from 'swr';
import axios from './api/axios';
import AppProvider from './AppProvider';
import Boot from './redux/boot';
import { store } from './redux/store';
import Routes from './router';

const App = () => {
  return (
    <Provider store={store}>
      <AppProvider>
        <>
          <GlobalStyles />
          <SWRConfig
            value={{
              fetcher(url, params) {
                return axios({
                  method: 'GET',
                  url,
                  params,
                }).then((res) => res.data);
              },
            }}
          >
            <Routes />
          </SWRConfig>
        </>
      </AppProvider>
    </Provider>
  );
};
Boot()
  .then(() => App())
  .catch((error) => console.error(error));

export default App;
