const actions = {
  UPDATE_USER_INFO: 'UPDATE_USER_INFO',
  UPDATE_USER2_INFO: 'UPDATE_USER2_INFO',
  RESET_USER_INFO: 'RESET_USER_INFO',
  RESET_USER2_INFO: 'RESET_USER2_INFO',
  RESET_All_USERS_INFO: 'RESET_All_USERS_INFO',

  updateUserInfo: (user) => ({
    type: actions.UPDATE_USER_INFO,
    user,
  }),
  updateUser2Info: (user2) => ({
    type: actions.UPDATE_USER2_INFO,
    user2,
  }),
  resetUserInfo: () => ({
    type: actions.RESET_USER_INFO,
  }),
  resetUser2Info: () => ({
    type: actions.RESET_USER2_INFO,
  }),
  resetAllUsersInfo: () => ({
    type: actions.RESET_All_USERS_INFO,
  }),
};
export default actions;
