import App from '@iso/redux/app/reducer';
import Auth from './auth/reducer';
import User from './user/reducer';
import ThemeSwitcher from '@iso/redux/themeSwitcher/reducer';
import { combineReducers } from 'redux';

export default combineReducers({
  ThemeSwitcher,
  App,
  Auth,
  User,
});
