export const PUBLIC_ROUTE = {
  LANDING: '/',
  SIGN_IN: '/signin',
  SIGN_UP: '/signup',
  FORGET_PASSWORD: '/forgotpassword',
  RESET_PASSWORD: '/resetpassword',
  PAGE_404: '/404',
  PAGE_500: '/500',
  AUTH0_CALLBACK: '/auth0loginCallback',
};

export const PRIVATE_ROUTE = {
  LIBRARY_IMAGE: '/library-image',
  LIBRARY_VIDEO: '/library-video',
  USER_MANAGE: '/user-manage',
  COURSE_MANAGE: '/course-manage',
  QUIZ_MANAGE: '/quiz-manage',
  CATEGORY_MANAGE: '/category-manage',
  UNIT_MANAGE: '/unit-manage',
  PARTNER_MANAGE: '/partner-manage',
  NOTICE_MANAGE: '/notice-manage',
  FAQ_MANAGE: '/faq-manage',
  TERM_MANAGE: '/term-manage',
  PRIVACY_MANAGE: '/privacy-manage',
  INQUIRY_MANAGE: '/inquiry-manage',
};
